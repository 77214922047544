@import './iconnect.scss';

.icast {
  //border: 1px solid #000;
  background-color: #fff;
  display: flex;
  overflow: hidden;
  &-video {
    & #sessionStatus {
      position: absolute;
    }
    &-publisher-wrapper{
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 180px;
      //border: 1px solid #ff0;
      overflow: hidden;
    }
    &-publisher {
      position: absolute;
      height: 100%;
      //border: 1px solid #000;
      bottom: 0;
      left:0;
      z-index: 5;
      border-radius: 0 5px 0 0;
      overflow: hidden;
    }
    &-subscriber-wrapper {
      //overflow-x: hidden;
      //overflow-y: auto;
      overflow: hidden;
      //height: calc(100vh - 1px);
      height: 100vh;
    }
    &-subscriber {
      //position: absolute;
      position: relative;
      //border: 1px solid red;
      width: 100%;
      top: 62px;
      height: calc(100vh - 62px);
    }
  }
  &-control {
    & button {
      border: none;
      border-radius: 50%;
      background-color: rgba(255,255,255, 0.75);
      width: 48px;
      height: 48px;
      margin: 0.5rem;
      & img {
        width: 28px;
        height: 28px;
      }
      &.endCall {
        background-color: rgba(200,0,0, 0.75);
      }
    }
    position: absolute;
    z-index: 10;
    top: 0;
    right: 0;
    left: 0;
    //display: flex;
    display: none;
    justify-content: center;
    min-width: 150px;
  }
  &-left {
    &:hover {
      .icast-control {
        display: flex;
      }
    }
    position: relative;
    flex: 1 1 34%;
    height: 100vh;
    border-right: 1px solid #bbb;
    background-color: #000;
    //border: 1px solid #f00;
    &-resizeBtn-wrapper {
      &-logo {
        position: absolute;
        top: 0;
        left: 0;
        background-color: #fff;
        height: 76px;
        width: calc(100% + 1px);
        & > img {
          position: relative;
          //border: 1px solid #f00;
          height: 44px;
          top: 15px;
          left: 5px;
        }
      }
    }
    &-resizeBtn {
      background: transparent;
      color: #000;
      position: absolute;
      right: -16px;
      //top: calc(50vh - 20px);
      bottom:calc(50% - 20px);
      border: 1px solid #bbb;
      border-left: none;
      border-radius: 0 10px 10px 0;
      //height: 40px;
      display: flex;
      align-items: center;
      font-size: 1.5rem;
      user-select: none;
      cursor: col-resize;

    }

  }
  &-embed {
    //border: 1px solid #f00;
    height: 100vh;
    &-iframe {
      //height: 100vh;
      //border: 1px solid #f00;
      display: block;
      margin: 0;
      padding: 0;
    }
    & > iframe{
      //border: 2px solid #f00;
      //width: 100%;
      //overflow: hidden !important;
    }
  }
  &-right {
    flex: 1 1 66%;
    //border: 1px solid #00f;
    height: 100vh;
  }
  &-right {
    &-dashboard-wrapper {
      padding: 1rem;
      overflow-x: hidden;
      overflow-y: auto;
      height: calc(100vh - 200px);
    }
  }
}

.video {
  &-admin{
    &-pub{
      display: flex;
      /*border: 1px solid #fff;*/
      width: 100%;
      justify-content: space-between;
    }
  }
}

