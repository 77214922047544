.guest_event_card {
  max-width: 400px;
  min-width: 300px;
}
.card-wrapper {
    &-wrapper {
    }
    background-color: #E8E8E8;
    padding: 1.5rem;
    min-height: 300px;
    width: 100%;
    margin: 10px;
  }
  .card-header-text{
      font-size: 45px;
      font-weight: 700;
      margin: 3px 0;
  }
  .card-date-text{
    font-size: 15px;
    font-weight: 700;
}
.card-time-text{
    font-size: 10px;
}
.card-topic{
    font-size: 14px;
    font-weight: 700;
}
.card-content{
    font-size: 10px;
}
.card-button{
   background-color: #c1b8b8;
   width: 100%;
   border: none;
}
.active-btn{
    background-color: #312882;
    color: white;
    padding: 5px 0px;
    text-transform: uppercase;
}
