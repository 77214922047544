.ClientView {
  &-wrapper {
    //border: 1px solid #f00;
    height: 100vh;
    position: relative;
    overflow: hidden;
  }
  &-logo {
    position: absolute;
    z-index: 1;
    right: 0;
    top: 0;
    & > img {
      width: 320px;
    }
  }
  &-screen{

    &-publisher-wrapper{
      position: absolute;
      bottom: 0;
      left: 0;
      //border: 1px solid #00f;
      border-radius: 0 5px 0 0;
      z-index: 10;
      overflow: hidden;
    }
    &-publisher{
      height: 240px
    }
    &-publisher-controls{
      //border: 2px solid #fff;
      position: absolute;
      bottom: 0;
      width: 100%;
      //height: 100vh;
      display: flex;
      opacity: 0;
      justify-content: center;
      z-index: 15;
      & button {
        border: none;
        width: 64px;
        height: 64px;
        border-radius: 50%;
        margin: 0.5rem;
        cursor: pointer;
        & > img {
          width: 32px;
        }
      }
      & .endCall {
        background-color: #922;
      }
      &:hover {
        opacity: 1;
      }
    }
    &-streams {
      //border: 2px dotted #000;
      height: 100vh;
    }
    &-subscriber{
      //border: 1px solid #0f0;
      height: 100vh;
    }
  }
}
