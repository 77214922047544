.myTable {
  &-wrapper{
    overflow-x: auto;
    & h3 {
      color: $text-primary;
      font-weight: bold;
      margin: $margin-primary 0;
    }
  }
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  border: 1px solid #ddd;
  color: $text-secondary;
  font-size: $font-size-tertiary;
  font-weight: bold;
  & th, td {
    padding: $padding-secondary;
  }
  & tr {
    cursor: pointer;
  }
  & tr:nth-child(odd){
    background-color: #e0e0e0;
  }
  & > thead > tr > th {
    background-color: #fff;
  }
  &-body {
    &-cell {
      display: flex;
      align-items: center;
      & .link{
        cursor: pointer;
      }
      & .download {
        position: relative;
        top: 2px;
        left: 5px;
        color: #25f;
      }
      & > img {
        width:16px;
        height: 16px;
      }
    }
  }

}
// #312182;
.CallLogs {
  &-nav{
    display: flex;
    justify-content: center;
    & > button {
      margin: 0.5rem;
      border: 1px solid #312182;
      color: #312182;
      padding: 5px 15px;
      cursor: pointer;
      &.call-active {
        border: 1px solid #312181;
        background-color: #312181;
        color: #fff;
      }
      &.email-active{
        border: 1px solid #312181;
        background-color: #312181;
        color: #fff;
      }
    }
  }
}

.FollowUpEmail {
  &-title {
    font-size: 1.5rem;
    color: #000;
    font-weight: bold;
  }
  &-email-active {
    font-size: 1rem;
    padding: 5px 15px;
    margin: 1rem 0;
    border: 1px solid #312181;
    background-color: #312181;
    color: #fff;
    cursor: pointer;
  }
}
