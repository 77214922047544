.NutritionDay {
  display: flex;
  justify-content: space-between;
  font-size: 1rem;
  & > div {
    margin: 0.15rem 0.5rem;
  }
  &-wrapper {
    background-color: #555;
    color: #fff;
    width: 100%;
    padding: 0.25rem;
  }
  &-wrapper-active {
    background-color: #222;
    color: #fff;
    width: 100%;
    padding: 0.25rem;
  }
  &-name {
    padding-left: 1rem;
  }
  &-navBtn {
    cursor: pointer;
    transform: rotate(90deg);
  }
  &-navBtn-active {
    cursor: pointer;
    transform: rotate(-90deg);
  }
  &-content {
    margin: 0.15rem 0;
    color: #000;
    &-nav {
      display: flex;
      background-color: #ccc;
      justify-content: space-between;
      margin: 0.25rem 0;
      padding: 0.5rem 1.5rem;
      &-active {
        display: flex;
        background-color: #888;
        justify-content: space-between;
        margin: 0.25rem 0;
        padding: 0.5rem 1.5rem;
      }
      &-sub {
        transform: rotate(-90deg);
        cursor: pointer;
      }
      &-sub-active {
        transform: rotate(90deg);
        cursor: pointer;
      }
    }
  }
  &-table-wrapper {
    overflow-x: auto;
  }
  &-table {
    max-width: 700px;
    margin: 0.5rem auto;
    //border: 1px solid #f00;
    width: 100%;
    & > table {
      width: 100%;
    }
  }

}

.summary {
  background-color: #ccc;
  cursor: pointer;
  &-expand {
    background-color: #888;
    cursor: pointer;
  }
  &-active{
    position: relative;
    transform: rotate(-90deg);
    left: .5rem;
  }
  &-inactive{
    transform: rotate(90deg);
  }
}
