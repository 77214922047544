.dashNotes {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;
  & > h3 {
    color: #000;
    font-weight: bold;
    font-size: 1rem;
    margin-bottom: 0;
  }
  &-button {
    border: none;
    background-color: #312182;
    color: #fff;
    border-radius: 3px;
    padding: 0.25rem 0.5rem;
    display: block;
  }
  &-indication {
    &-wrapper {
      width: 40px;
      margin-bottom: 0.5rem;
    }
    background: linear-gradient(90deg, #c00, #903, #60f);
    height: 5px;
    border-radius: 3px;
  }
  &-removeNoteBtn {
    cursor: pointer;
    font-size: 1.3rem;
    border: 1px solid #f00;
    border-radius: 50%;
    width: 32px;
    text-align: center;
    height: 32px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    color: #f00;
    margin: 0.5rem;
  }

}

