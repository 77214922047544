// Variable overrides
$padding-primary: 1.5rem;
$padding-secondary: 0.75rem;
$padding-tertiary: 0.375rem;
$padding-quaternary: 0.2rem;

$margin-primary: 1.5rem;
$margin-secondary: 0.75rem;
$margin-tertiary: 0.375rem;

$text-primary: #000;
$text-secondary: #333;
$text-tertiary: #fff;
$text-quaternary: #666;
$black-20: #ccc;

$text-primary-nav: #666;

$font-size-primary: 1.2rem;
$font-size-secondary: 1rem;
$font-size-tertiary: 0.75rem;
$font-size-quaternary: 0.65rem;

$background-primary: #f5f5f5;
