@mixin link-default(){
  color: #2b2d75;
  cursor: pointer;
  &:hover{
    text-decoration: underline;
  }

}

.DashNavNutrition {
  border: 1px solid #00f;
  &-wrapper {
    //border: 1px solid #f00;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  &-Pagination-wrapper {
    display: flex;
    & > div {
      margin: 0.5rem;
    }
  }
  &-Pagination {
    &-range {
      color: #000;
      font-weight: bold;
    }
    &-prev {
      @include link-default;
    }
    &-next {
      @include  link-default;
    }
  }
  &-buttons-wrapper{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  &-buttons {
    min-width: 130px;
    margin: 0.25rem;
    font-size: 0.8rem;
    padding: 0.25rem;
    border: none;
    &.week {
      border: 1px solid #000;
    }
    &.week-active {
      background-color: #000;
      border: 1px solid #000;
      color: #fff;
    }
  }
  &-content-wrapper {
    width: 100%;
    margin: 1rem;
  }
  &-content {
    margin: 0.5rem 0;
    width: 100%;
  }

}

.active {
  &-macros {
    background-color: #408228;
    color: #fff;
  }
  &-food {
    background-color: #312182;
    color: #fff;
  }
  &-cals {
    background-color: #ef5248;
    color: #fff;
  }
}
.inactive {
  &-macros {
    color: #408228;
    background-color: #fff;
    border: 1px solid #408228;
  }
  &-food {
    color: #312182;
    background-color: #fff;
    border: 1px solid #312182;
  }
  &-cals {
    color: #ef5248;
    background-color: #fff;
    border: 1px solid #ef5248;
  }
}


.test {
  &-carbo{
    background-color: #ec9e30;
    color: #222 !important;
  }
  &-saturated{
    background-color: #84987e;
    color: #222 !important;
  }
  &-weight {
    background-color: #000;
    color: #fff !important;
  }
}




