.DNav{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 2rem;
  //border: 1px solid #f00;
  &-wrapper {
    // If prefer sticky comment out position: fixed & width 100%
    // Also adjust paddding-top in client-dashboard.scss (body-content)
    //position: sticky;
    position: fixed;
    width: 100%;
    z-index: 100;
    background-color: #efefef;
    //border: 1px solid #ff0;
  }
  &-nav {
    //border: 1px solid #0f0;
    flex: 1 1 90%;
    display: flex;
    justify-content: center;
    &-group{
      display: flex;
      align-items: center;
      max-width: 500px;
      & > div:first-child {
        margin-right: 1rem;
      }
    }
    &-input{
      &-date {

      }
    }
    &-icon {
      &-wrapper{
        flex: 1 1 10%;
        margin-left: 1rem;
      }
      border: 1px solid #312182;
      padding:2px;
      margin: 0;
      cursor: pointer;
      color: #312182;
    }
  }

  &-Pagination {
    &-wrapper {
      background-color: #ddd;
      color: #000;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0.25rem 2rem;
    }
    &-prev {
      cursor: pointer;
      &::before {
        content: '\2023';
        position: absolute;
        transform: translateX(-10px) rotate(180deg);
      }
    }
    &-next {
      cursor: pointer;
      &::after {
        content: '\2023';
        position: absolute;
        transform: translateX(10px);
      }
    }
    &-range {
      font-weight: bold;
      font-size: 0.8rem;
    }
  }

}

.icon-active {
  background-color: #312182;
  color: #fff !important;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  background-color: #312182;
  //filter: invert(100%);
  color-scheme: dark;
  padding: 5px;
}
