.chatMsgContainer {
  border: 1px solid #bbb;
  box-shadow: 1px 1px 1px -2px #bbb;
  border-bottom: none;
  margin: $margin-tertiary 0;
  //border: 1px solid #00f;

  &-clearBoth {
    clear: both;
    margin-bottom: 5rem;
  }
}
