@mixin link-general($bg-value, $color-val) {
  text-decoration: none;
  background-color: $bg-value;
  cursor: pointer;
  color: $color-val;
}

@mixin link-default(){
  color: #2b2d75;
  cursor: pointer;
  :hover{
    text-decoration: underline;
  }

}

.client {
  &-profile {
    padding: $padding-secondary;
    &-top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      & button {
        border: none;
        border-radius: 5px;
        background-color: #333;
        color: #fff;
        cursor: pointer;
      }
    }
    &-header {
      display: flex;
      align-items: center;
      //flex-wrap: wrap;
      padding: $padding-secondary;
      //border: 1px solid #00f;
      &-links {
        display: flex;
        //flex-direction: column;
        //min-height: 65px;
        flex-wrap: wrap;
        //border: 1px solid red;
        //padding: $padding-secondary $padding-primary $padding-secondary 3rem;
      }
      &-box {
        font-size: $font-size-tertiary;
        display: flex;
        min-width: 300px;
        justify-content: space-between;
        //border: 1px solid red;
      }
      &-top {
        button {
          border: none;
          background-color: #31227e;
          color: $text-tertiary;
          padding: 0 $padding-tertiary;
          border-radius: 3px;
          text-transform: capitalize;
          font-size: $font-size-tertiary;
        }
        &-email a {
          @include link-general(#fff, #5173b2);
          text-transform: capitalize;
          visibility: hidden;
        }
        &-checkbox {
          display: flex;
          align-items: center;
          & label {
            margin-bottom: 0.1rem;
          }
        }
      }
      &-bottom {
        width: 100%;
        //max-width: 300px;
        margin: $margin-tertiary 0 0 0;
        color: #000;
        //border: 1px solid #00f;
        &-link {
          //border: 1px solid #f00;
          display: flex;
          width: 100%;
          //justify-content: space-between;
          align-items: center;
          font-size: $font-size-quaternary;
          cursor: pointer;
          & img {
            margin-left: $margin-secondary;
          }
          &:first-child {

          }
        }
        &-copy{
          @include link-default();
          margin-right: 0.5rem;
        }
        &-start {
          margin-left: 0.5rem;
        }

      }

    }
    &-image {
      position: relative;
      min-width: 128px;
      border-radius: 50%;
      overflow: hidden;
      margin-right: 2rem;
      & button {
        position: absolute;
        padding: 0 0 $padding-tertiary 0;
        outline: none;
        border: none;
        background-color: #eee;
        bottom: 0;
        left:0;
        width: 100%;
      }
    }
  }
}

.client {
  &-form {
    width: 100%;
    color: $text-primary;
    &-col {
      &-left {
        border-right: 1px solid $black-20;
      }
    }
    & label {
      font-size: $font-size-tertiary;
    }
    &-group {
      display: flex;
      &-item {
        margin: 0 $margin-tertiary;
      }
    }
    &-buttons{
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      align-items: center;
      font-size: $font-size-tertiary;
      &-save{
        margin: $margin-secondary;
        padding: $padding-tertiary;
        min-width: 75px;
        border: none;
        color: $text-tertiary;
        border-radius: 4px;
        background: linear-gradient(90deg, #c00, #903, #60f);
        cursor: pointer;
      }
      &-back{
        margin: 0 $margin-secondary;
        padding: $padding-tertiary;
        min-width: 75px;
        border: 1px solid $text-quaternary;
        color: $text-secondary;
        border-radius: 4px;
        cursor: pointer;
      }
    }
  }


}
@media only all and (max-width: 777px){
  .client{
    &-profile{
      &-header{
        flex-direction: column;
        &-links {
          margin-top: 1rem;
        }
      }
    }
  }
}

@media only all and (max-width: 500px){
  .client {
    &-profile {
      &-header {
        &-links {

        }
        flex-direction: column;
        overflow: hidden;

        &-box {
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }
        &-bottom {
          &-link {
            flex-direction: column;
            flex-wrap: wrap;
            word-break: break-all;
          }
        }
      }
    }
  }
}
