.login__form {
  &__container {
    padding: $padding-primary;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
    //border: 1px solid #000;
    background-color: #fff;
  }
  &_img {
    //border: 1px solid red;
    width: 100%;
    margin-bottom: $margin-primary;
  }
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  & > div {
    margin: 0.75rem;
  }
  & > div > label {
    display: block;
    font-size: $font-size-tertiary;
    width: 100%;
    max-width: 320px;
    margin: 0 auto;
    text-align: left;
    color: $text-primary;
    margin-bottom: $margin-tertiary;
    //border: 1px solid #000;
  }
  & > div > input {
    //border: 1px solid #00f;
    width: 100%;
    min-width: 320px;
    border: 1px solid #bbb;
    border-radius: 3px;
    outline-color: #666;
    padding: $padding-tertiary;
  }

  & input[type="submit"] {
    //font-weight: bold;
    //display: block;
    font-size: $font-size-tertiary;
    margin: $margin-secondary;
    padding: $padding-tertiary;
    min-width: 115px;
    border: none;
    color: $text-tertiary;
    border-radius: 4px;
    background: linear-gradient(90deg, #c00, #903, #60f);
    //border: 1px solid #0f0;
  }




}
.login__form > div {
  margin: 0.6rem 0.375rem;
}
