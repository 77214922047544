.DBody {
  &:last-child {
    margin-bottom: 1.5rem;
  }
  &-wrapper {
    //border: 1px solid #000;
    //height: calc(130vh - 150px);
    //overflow-x: hidden;
    //overflow-y: auto;
    // DashBody below fixed navigation
    //padding-top: 5rem;
  }
  &-container {
    border: 2px solid #bbb;
  }
  &-row {
    margin: 0.25rem 0;
    //border: 1px solid #f00;
    //justify-content: center !important;
    & .rowToCenter {
      //border: 1px solid red;
      width: 100%;
      justify-content: center;
    }

  }
  &-row-mid {
    margin: 0.5rem 0.35rem;
    //border: 2px solid #bbb;
    &-title, &-subtitle {
      //border: 1px solid #000;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      color: #000;
      text-transform: uppercase;
      font-weight: bold;
      font-size: 0.8rem;
      margin: 0.5rem;
    }
    &-controls {
      display: flex;
      justify-content: center;
      & button{
        margin: 0.25rem;
        outline-color: #ccc;
      }

    }
    &-subtitle {
      font-size: 0.7rem;
      text-align: center;
      &-details {
        color: #777;
        text-transform: capitalize;
        font-weight: normal;
      }
    }
  }
  &-col {
    padding: 0.35rem;
    //border: 1px solid #000;
    &-indication {
      margin: 0 auto;
      width: 60px;
      background: linear-gradient(90deg, #c00, #903, #60f);
      height: 5px;
      border-radius: 3px;
    }
    & > div {
      //border: 2px solid #bbb;
      height: 100%;
    }
    & > div > div {
      padding: 0.35rem;
      width: 100%;
      //height: 100%;
    }
    &-pie {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-evenly;
      align-items: center;

      &-left {
        width: 100%;
        //border: 1px solid #000;
      }
      &.inner{
        width: 90%;
        margin: -3rem 0;
      }

    }
    &-top{
      & > div {
        height: 100%;
        //border: 1px solid #000;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100%;
      &-item {
        display: flex;
        justify-content: flex-start;
        margin: 0.5rem 0;
        & > p{
          margin: 0 0.5rem;
          color: #000;
          text-transform: uppercase;
          font-size: 0.6rem;
          font-weight: bold;
        }
      }
    }

  }


}

.DBody {
  &-nav-wrapper {
    display: flex;
    justify-content: center;
    //border: 1px solid #f00;
    width: 100%;
    text-align: center;
    position: relative;
    font-size: 1.5rem;
    color: #000;
    font-weight: bold;
    & .toCenter {
      width: 100%;
    }
    & .toRight {
      position: absolute;
      right: 0;
      & > div {
        cursor: pointer;
      }
    }
    & .line {
      border-bottom: 1px solid #aaa;
      margin: 1.5rem auto 0.25rem auto;

      max-width: 350px;
    }
    & .normal {
      transform: rotate(-90deg);
    }
    & .closed {
      transform: rotate(+90deg);
    }
  }
  &-text {
    &-black {
      color: #000;
    }
    &-black-bold {
      color: #000;
      font-weight: bold;
    }
  }
  &-graph {
    &-nav-wrapper {
      max-width: 800px;
      margin: 0.5rem auto;
      //border: 2px solid #f00;
    }
    &-nav {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      color: #000;
      //border: 1px solid #00f;
      &-items {
        margin: 0 1rem;
        display: flex;
        align-items: center;
      }
      &-item{
        border: 1px solid #312182;
        color: #312182;
        background-color: #fff;
        padding: 0 3px;
        margin: 1px;
        cursor: pointer;
      }
      &-item-active {
        border: 1px solid #312182;
        background-color: #312182;
        color: #fff;
        padding: 0 3px;
        margin: 1px;
        cursor: pointer;
      }
    }
  }
  &-tir-buttons {
    margin: 1rem 5rem;
    & > div {
      display: flex;
      justify-content: space-between;
    }
    &-btn {
      border: 1px solid #000;
      padding: 0.25rem;
      min-width: 100px;
      cursor: pointer;
      &-active {
        background-color: #000;
        color: #fff;
      }
    }

  }

}


.active {
  &-carbo{
    background-color: #ec9e30;
    color: #222 !important;
  }
  &-sugar {
    background-color: #ef5248;
    color: #222 !important;
  }
  &-saturated{
    background-color: #84987e;
    color: #222 !important;
  }
  &-salt{
    background-color: #312182;
    color: #fff !important;
  }
  &-weight {
    background-color: #000;
    color: #fff !important;
  }
  &-freq {
    background-color: #408228;
    color: #fff !important;
  }

}
