.usersList {
  margin: $margin-secondary 0;
  padding: $padding-secondary 0;
  //border: 1px solid #000;
  display: flex;
  &:hover{
    cursor: pointer;
    background-color: #e5e5e5;
  }
  &-details {
    display: flex;
    flex-direction: column;
    font-size: $font-size-tertiary;
    margin-left: $margin-secondary;
    &-username {
      font-weight: bold;
      color: $text-primary;
    }
    &-content {

    }
  }
}
