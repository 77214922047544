@mixin link-default(){
  color: #2b2d75;
  cursor: pointer;
  border: none;
  background-color: #fff;
  outline: none;
  :hover{
    text-decoration: underline;
  }
  :focus {
    outline: 1px solid #bbb;
  }

}

.ClientDashboard {
  &-wrapper {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: #fff;
    z-index: 10000;
  }
  &-header-wrapper {
    display: flex;
    //border: 1px solid #f00;
    box-shadow: 1px 1px 2px 2px #bbb;
    min-height: 70px;
  }
  &-header {
    &-nav {
      display: flex;
      align-items: center;
      margin: 0.5rem;
      & > button {
        @include link-default();
      }
    }
    &-title {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0.5rem;
      //border: 1px solid #00f;
      width: 100%;
      font-size: 1.2rem;
      font-weight: bold;
      text-transform: uppercase;
      color: #000;
    }
  }
  &-body-wrapper {
    //background-color: #aaa;
    width: 100%;
    height: calc(100vh - 70px);
    overflow-x: hidden;
    overflow-y: auto;
  }
  &-body{
    &-nav {

    }
    &-content {
      margin: 0 auto;
      max-width: 1000px;
      padding-top: 6rem;
    }
    &-content-inSplit {
      margin: 0 auto;
      max-width: 1000px;
      padding-top: 1rem;
    }
  }

}
