.live {
  overflow-x: auto;
  overflow-y: hidden;
  max-height: 78vh;
  //overflow: no-content;
}


.liveChat {
  display: flex;
  min-width: 500px;
  max-height: 78vh;

  &-userList {
    //border: 1px solid #aaa;
    position: relative;
    flex: 1 1 25%;
    max-width: 260px;
    padding: $padding-quaternary;
    overflow-x: hidden;
    max-height: 78vh;
    & h3 {
      position: sticky;
      width: 100%;
      top: -3px;
      padding: $padding-tertiary;
      background-color: $background-primary;
      font-weight: bold;
      color: $text-primary;

    }
  }
  &-activeChat {
    //border: 1px solid #bbb;
    flex: 1 1 75%;
    padding: $padding-tertiary;
    overflow-x: hidden;
    //overflow-y: scroll;
    max-height: 71vh;

  }
  &-sendPanel {
    position: absolute;
    bottom: -0.5rem;
    right: 1rem;
    left: 0;
    padding: 0.2rem 0.2rem 0.2rem 40%;
    //border: 1px solid #f00;

    margin-bottom: 1rem;
    &-box {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      &-msg {
        width: 75%;
        position: relative;
        overflow: hidden;
        & input {
          display: block;
          width: 100%;
          border: 1px solid #747a86;
          border-radius: 5px;
          overflow: hidden;
          padding: 5px;
        }

        & > img {
          display: block;
          position: absolute;
          padding: 5px 0;
          border-radius: 0 5px 5px 0;
          //width: 20px;
          height: 100%;
          background-color: #747a86;
          top: 0;
          right: 0;
        }
        &-icon {
          //display: flex;
          //justify-content: center;
        }
      }
      &-check {
        display: flex;
        align-items: center;
        margin: 0 1rem;
        & label {
          position: relative;
          bottom: -4px;
          margin-left: 0.25rem;
        }
      }
      &-submit {
        position: relative;
        & input {
          border: none;
          background: linear-gradient(90deg, #c00, #903, #60f);
          border-radius: 5px;
          color: #fff;
          min-width: 70px;
          padding: 0.35rem 0.5rem;
          font-size: 0.75rem;
          text-align: left;
        }
        & button {
          border: none;
          background: linear-gradient(90deg, #c00, #903, #60f);
          border-radius: 5px;
          color: #fff;
          min-width: 70px;
          padding: 0.35rem 0.5rem;
          font-size: 0.75rem;
          text-align: left;
        }
        & img {
          position: absolute;
          right: 6px;
          top: 4px
        }

      }
    }
  }


}
::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  background-color: darkgrey;
}

::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}
