.chatCard {
  background-color: #fff;
  border: 1px solid #bbb;
  box-shadow: 1px 1px 1px 1px #bbb;
  position: sticky;
  top: -6px;
  margin-top: -6px;
  z-index: 2;

  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    //border: 1px solid red;

    &-icon {
      display: flex;
      align-items: center;
      padding: $padding-tertiary;
      & > div {
        margin-left: $margin-primary;
      }
    }
    &-select {
      margin-right: $margin-tertiary;
      display: flex;
      align-items: center;
      &-connection {
        margin-right: 0.5rem;
        position: relative;
        top:5px;
        &-box{
          display: inline-block;
          width: 12px;
          height: 12px;
          border-width: 1px;
          border-style: solid;
          border-color: #333;
          margin-right: 0.5rem;
          &-active {
            background-color: #0f0;
          }
          &-inactive {
            background-color: #f00;
          }
        }
      }
      &-item {
        //border: 1px solid red;
        display: flex;
        justify-content: space-between;
        width: 100%;

      }
      &-subItem {
        display: flex;
        align-items: center;
        margin-left: $margin-primary;
        min-width: 80px;
        &-online{
          width: 12px;
          height: 12px;
          border: 1px inset #aaa;
          background-color: #5b1b67;
        }
        &-away{
          width: 12px;
          height: 12px;
          border: 1px inset #aaa;
          background-color: #312182;
        }
        &-offline{
          width: 12px;
          height: 12px;
          border: 1px inset #aaa;
          background-color: #d50913;
        }
        & div:nth-child(2) {
          margin-left: $margin-tertiary;
          color: $text-primary;
          font-size: $font-size-tertiary;
        }
      }
    }
    &-menu {
      background-color: #515c70;
      border-radius: 5px;
      position: relative;
      //min-width: 80px;
      text-align: center;
      & button {
        color: $text-tertiary;
        &:hover {
          color: #fff;
        }
      }
    }
  }

  &-body {
    min-height: 100vh;
  }
}

.chatCard {
  &-statusBox {
    width: 12px;
    height: 12px;
    border-width: 1px;
    border-style: solid;
    border-color: #fff;
    margin-right: 0.5rem;
  }
  &-online {
    background-color: rgb(91,27,103);
  }
  &-away {
    background-color: rgb(49,33,130);
  }
  &-offline{
    background-color: rgb(213,9,19);
  }
}
