.ComposeEmail {
  &-wrapper{
    //border: 1px solid red;
    margin: $margin-primary $margin-secondary;
    padding: $padding-secondary;
  }
  &-body {
    //border: 1px solid #f00;
    position: relative;
    &-button {
      position: absolute;
      right: 7px;
      top: 5px;
      border: none;
      background-color: #666;
      color: #fff;
      padding: 5px 15px;
      cursor: pointer;
      border-radius: 3px;
    }
  }
  &-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: $margin-secondary 0;
    &-title {
      color: #000;
      font-size: 1.2rem;
      font-weight: bold;
    }
    &-controls {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      //border: 1px solid #0f0;
      &-btn {
        margin: $margin-tertiary;
        //border: 1px solid #f00;
      }
    }

  }
  &-subscript {
    visibility: hidden;
  }
  &-superscript {
    visibility: hidden;
  }
  &-strikethrough {
    visibility: hidden;
  }
  &-monospace {
    visibility: hidden;
  }

}
