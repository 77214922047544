.dashHeader {
  &-wrapper {
    position: sticky;
    display: flex;
    justify-content: center;
    padding: 1rem 0.5rem;
    //height: 60px;
    border-bottom: 1px solid #bbb;
  }
  &-title {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    color: #000;
    & h1 {
      font-size: 1.2rem;
      font-weight: bold;
      margin-bottom: 0;
    }
  }
  &-control {
    position: absolute;
    right: 2rem;
    transform: translateY(calc(50% - 0.15rem));
    & > button {
      border: none;
      border-radius: 5px;
      color: #fff;
      background-color: #312182;
    }
  }

}

@media only all and (max-width: 800px){
  .dashHeader {
    &-control {
      //position: relative;
      //display: block;
      //transform: none;
      //border: 1px solid red;
      right: 0;
      top: -10px;
    }
  }
}
