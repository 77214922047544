@import "../views/components/card/event-card.scss";
.wrapper-div{
    min-height: 600px;
}
.top-div{
    background-color: lightgrey;
    padding: 20px 100px;
}
.connect{
    margin-left: 10px;
    padding: 5px 10px;
    border-radius: 10px;
    color: white;
    font-size: 15px;
    background-color: #312882;
    font-weight: 600;
}
.events-div{
    width: 70%;
    border: 1px solid none;
    margin: 30px auto 0px auto;
    padding: 30px 0;
    position: relative;
}
.event-head{
    text-transform: uppercase;
    text-align: center;
    font-size: 30px;
}
.event-head::after{
    content: "";
    display: block;
    height: 4px;
    width: 6%;
    margin:5px  auto;
    background-image: linear-gradient(to right, #D90712, #312882);
    border-radius: 20px;
}
.session-div{
    width: 70%;
    border: 1px solid none;
    margin: 5px auto 0px auto;
    padding: 10px 0;
    position: relative;
}
.session-head{
    text-transform: uppercase;
    text-align: center;
    font-weight: 600;
    color: black;
    font-size: 30px;
}
.session-head::after{
    content: "";
    display: block;
    height: 4px;
    width: 10%;
    margin:5px  auto;
    background-image: linear-gradient(to right, #D90712, #312882);
    border-radius: 20px;
}
.event-tab{
    text-align: center;
    border-bottom: 2px solid darkgrey;
    margin: 0px 100px;
}
.event-categories{
    display: inline-block;
    cursor: pointer;
    margin: -2px 15px;
}
.card-area{
    padding: 20px 100px;
    display: none;
    width: 100%;
}
.active-event-card{
    display: flex;
}
.active-event-tab{
    border-bottom: 3px solid #312882;
    padding: 5px 0px;
}
.bottom-btn-div{
    text-align: center;
    margin: 10px;
}
.bottom-btn{
    background-color: #312882;
    color: white;
    border: none;
    padding: 5px 30px;
}
.component-wrapper{
    display: flex;
    flex-direction: column;
    width: 100%;
}
.meeting-area{
    //min-width: 800px;
    //flex: 1 1 100%;
    //background-color: black;
    //position: relative;
    position: relative;
    width: 100%;

}
.other-area{
    //min-width: 40%;
    //height: 500px;
    //padding: 5px 30px;
}
.faq{
    border: 1px solid black;
    min-height: 50px;
    margin: 15px 0;
    padding: 10px 10px;
    position: relative;
}

.chat{
    border: 1px solid black;

    margin: 15px 0;
    padding: 10px 20px;
    background-color: white;
    position: relative;
    z-index: 10;
}
.chat-scroll{
    max-height: 350px;
    overflow-y: scroll;
    overflow-x: hidden;
}
.chat-scroll-hide{
    display: none;
}
.chat-scroll-active{
    display: block;
}
.faq-scroll{
    display: none;
}
.faq-scroll-active{
    display: block;
}
.little-arrow{
    display:inline-block;
    height: 20px;
    width: 20px;
    background-color: black;
    position: absolute;
    right: 20px;
    font-weight: 900;
    cursor: pointer;
}

.q-asked{
    font-size: 14px;
    text-transform: uppercase;
    text-align: center;
    font-weight: 700;
}
.q-asked::after{
    content: "";
    display: block;
    height: 4px;
    width: 15%;
    margin:5px  auto;
    background-image: linear-gradient(to right, #D90712, #312882);
    border-radius: 20px;
}
.q-text{
    font-size: 10px;
    text-align: center;
    margin-bottom: 20px;
}
.events-t{
    font-size: 9px;
}
table{
    width: 100%;
}
.chat-head{
    font-size: 14px;
    text-transform: uppercase;
    text-align: center;
    font-weight: 700;
}
.chat-head::after{
    content: "";
    display: block;
    height: 4px;
    width: 15%;
    margin:5px  auto;
    background-image: linear-gradient(to right, #D90712, #312882);
    border-radius: 20px;
}
.chat-title{
    font-size: 10px;
    text-align: center;
    margin-bottom: 20px;
}
.msg-chat{
    min-height: 80px;
    border-radius: 15px 15px 15px  15px;
    border: none;
    background-color: #dfd9d9;
    margin: 15px 0;
    position: relative;
    padding: 10px;
}
.reciever-msg{
    background-color: rgb(215,230,248);
}
.little-chat-style-sender{
    transform: rotateZ(140deg);
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 25px solid #dfd9d9;
    position: absolute;
    right: -10px;
    bottom: -10px;
}
.little-chat-style-reciever{
    transform: rotateZ(-140deg);
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 25px solid rgb(215,230,248);
    position: absolute;
    left: -10px;
    bottom: -10px;
}
.chat-name{
    font-weight: 700;
    font-size: 10px;
}

.chat-time{
    float: right;
}
.chat-content{
    font-size: 10px;
    position: relative;
    z-index: 2;
}

.inner-meeting-area{
    //display: flex;
  //position:relative;
  //width: 100%;
  //min-height: 50vh;
}
.inner-meeting-area > div {
  //position: absolute;
  //top:0;
  //left: 0;
  //right: 0;
  //bottom:0;
}
.meeting-pics{
    height: 200px;
    width: 50%;
    border: .1px solid rgb(143, 136, 136);
    margin-top: 100px;
}
.meeting-button{
    margin-top: 100px;
    text-align: center;
    padding: 0px 10px;
    position: relative;
    padding-bottom: 30px;
    border-bottom: 1px solid white;

}
.inner-meeting-button{
    // display: inline-block;
    height: 30px;
    width: 30px;
    margin: 5px;
    border-radius: 50%;
    background-color: white;
    padding: 10px 15px;
}
.inner-meeting-button-last{

    padding: 3px 13px;
    background-color: red;
    color: white;
    position: absolute;
    // right: 40px;
    right: 10px;
    cursor: pointer;
}
.inner-btn{
    border: none;
    background-color: red;
    color: white;
    padding: 5px 10px;
    position: relative;
    z-index: 10px;
    margin-top: 10px;
    display: block;
    width: 100%;
}
.show-div{
    display: none;
}
.hide-div{
    display: block;
}
.end-meeting-arrow{
    width: 0;
    height: 0;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 17px solid #dfd9d9;
    transform: rotateZ(180deg);
    position: absolute;
    bottom: -10px;
    right: 38%;
    text-align: left;
    z-index: 9;
}
.end-meeting-div{
    font-size: 10px;
    padding: 5px;
    height: 80px;
    width: 150px;
    position: absolute;
    right: 20px;
    background: #dfd9d9;
    top: -94px;
    line-height: 15px;
}
.dummy-img{
    width: 100%;
    height: 100%;
}
.been-recorded{
    font-size: 9px;
    color: white;
    padding: 10px 20px;
}
.indicator{
    display: inline-block;
    height: 8px;
    width: 8px;
    border: 1px solid red;
    border-radius: 50%;
    margin-right: 5px;
    line-height: 20px;
}
.iconnect-img{
    margin: 150px auto;
    text-align: center;

}
.main-iconnect-img{
    height: 50px;
}
.guest-area{
    min-height: 100px;
    border-top: 1px solid white;
    display: flex;
}
.guest-img-div{
    width: 25%;
    height: 150px;
    border: 1px solid white;
}
.guest-img{
    height: 100%;
    width: 100%;
}
.question-area{
    display: flex;
    padding: 0 10px;
}
.question-area-active{
    display: flex !important;
}
.question-area-hide{
    display: none !important;
}
.input-area{
    width: 80%;
    padding: 0px 10px;
    border-right: 1px solid grey;
}
.send-btn-area{
    padding: 0px 10px;
    width: 20%;
}
.input-div{
    border: 1px solid grey;
    border-radius: 3px;
    display: flex;
    overflow: hidden;
}
.form-input{
    width: 100%;
    border: none;
    outline: none;
    padding: 5px 10px;
    background-color: transparent;
}
.input-actual-div{
    width: 85%;
}
.input-append-div{
    width: 15%;
    height: 100%;

}
.my-btn{
    width: 100%;
    font-size: 20px;
    border: none ;
    background-color: #757B87;
}
.send-btn{
    background-image: linear-gradient(to right, #D90712, #312882);
    border-radius: 3px;
    border: 1px solid transparent;
    padding: 5px;
    color: white;
}

.screenshare{
    width: 100%;
}

.chart-head{
    font-size: 24px;
    text-transform: uppercase;
    text-align: center;
    font-weight: 700;
}
.chart-head::after{
    content: "";
    display: block;
    height: 4px;
    width: 5%;
    margin:5px  auto;
    background-image: linear-gradient(to right, #D90712, #312882);
    border-radius: 20px;
}

.chart-div{
    display: flex;
}
.main-chart{
    width: 70%;
}
.analysis-chart{
    padding: 10px 20px;
    width: 30%;
}
.chart-key{
    list-style-type: square;
    list-style-position: inside;
    margin: 0px;
    padding: 0;
    font-size: 14px;
    line-height: 2;
}
.table-border{
    margin: 20px 5px;
    border: 1px solid grey;
    border-collapse: collapse;
}
.chart-top-div{
    text-align: center;
    position: relative;
}
.chart_msn_img{
    height: 30px;
    width: 30px;
}
.chart-connect{
    margin-left: 10px;
    padding: 5px 5px;
    border-radius: 7px;
    color: white;
    font-size: 12px;
    background-color: #312882;
    font-weight: 600;
}
.download{
    position:absolute;
    right: 10px;
    top: 0px;
    cursor: pointer;
}
.reg__form_img{
    height: 100px;
}


@media (max-width: 992px) {
    //.meeting-area{
    //    flex: 0 0 auto;
    //    width: 100% !important;
    //}
    .other-area{
        flex: 0 0 auto;
        width: 100% !important;
        padding: 5px 0px;
    }
    .card-area{
        padding: 20px 20px;
        width: 100%;
    }
    .event-tab {
        margin: 0px 20px;
        position: relative;
    }
    .event-head::after {
        width: 20%;
    }
    .top-div {
        padding: 20px 20px;
    }
    .event-head {
        font-size: 20px;
    }
    .active-event-card{
        display: flex;
        flex-wrap: wrap;
    }
    .chart-div{
        display: flex;
        flex-wrap: wrap;
    }
    .analysis-chart {
        padding: 10px 20px;
        width: 100%;
    }
  }

@media only all and (min-width: 1200px){
  .component-wrapper {
    flex-direction: row;
    justify-content: center;
    min-height: 90vh;
  }
  .inner-meeting-area {
    //min-height: 90vh;
  }
}
