// Here you can add other styles
@import './clientProfile.scss';
@import './callLogs.scss';

@import './liveChat.scss';
@import './usersList.scss';
@import './activeChatBody.scss';
@import './activeChatHeader.scss';

@import './compose-email.scss';
@import './events.scss';

@import './icast.scss';

@mixin link-common($bg-value, $text-color) {
  text-decoration: none;
  color: $text-color;
  background-color: $bg-value;
  cursor: pointer;
}

.text-center {
  text-align: center;
  width: 100%;
}

.sideBar {
  &-Header {
    background-color: #fff;
  }
  &-Brand{
    text-align: center;
    padding: $padding-secondary;
    &-mobile {
      margin: 0 auto;
      //border: 1px solid #f00;
    }
  }
  &-Title {
    color: $text-primary-nav;
    text-transform: uppercase;
    padding: $padding-secondary $padding-tertiary;
    /*&:hover {
      color: $text-secondary;
      text-decoration: none;
      cursor: default;
    }*/

  }
  &-Nav {
    padding: $padding-primary;
    & > a {
      margin: $margin-tertiary 0 !important;
      padding: $padding-tertiary;
      &:link{
        @include link-common(#fff, $text-primary-nav);
      }
      &:visited{
        @include link-common(#fff, $text-primary-nav);
      }
      &:focus{
        @include link-common(#fff, $text-primary-nav);
      }
      &:hover{
        @include link-common(#fff, $text-primary);

      }
      &:active{
        @include link-common(#fff, $text-primary);
      }
    }

    &-item-container {
      display: flex;
      align-items: center;
      //border: 1px solid #f00;
    }
    &-item {
      &-icon {
        color: $text-secondary;
        height: 16px;
        margin-right: $margin-secondary;
      }

    }

  }

}


.icons {
  &-subheader {
    display: flex;
    align-items: center;
    &-item{
      display: flex;
      align-items: center;
      margin: 0 $margin-tertiary;
      > div {
        margin: 0 $margin-tertiary;
      }
    }
  }
}

.navLink {
    margin: $margin-tertiary 0 !important;
    padding: $padding-tertiary;
    &:link{
      @include link-common(#fff, $text-primary-nav);
    }
    &:visited{
      @include link-common(#fff, $text-primary-nav);
    }
    &:focus{
      @include link-common(#fff, $text-primary-nav);
    }
    &:hover{
      @include link-common(#fff, $text-primary);

    }
    &:active{
      @include link-common(#fff, $text-primary);
    }
}


.header {
  &-title{
    color: $text-secondary;
    font-family: sans-serif;
    font-size: 1.1rem;
    font-weight: bold;
    margin: $margin-secondary $margin-tertiary $margin-tertiary $margin-secondary;
  }
}

.c-subheader-nav a{
 color: $text-primary-nav;
  &:hover {
    @include link-common(#fff, $text-primary);
  }
}

// CDataTable Class
.c-body {
  background-color: #f5f5f5;
}
.row {
  &-active {
    background-color: #ddf0dd;
  }
  &-pending {
    background-color: #f0f0dd;
  }
  &-banned {
    background-color: #f0dddd;
  }
  &-inactive {
    background-color: #d0d0d0;
  }
}

.container-fluid {
  > .fade.show {
    > div {
      margin: $margin-primary 0;
    }
  }
}

.user-management-user-item-active > td{
  background-color: #333;
  color: #eee;
}
.user-management-user-item-active {
  background-color: #333;
  color: #eee;
}


