// If you want to override variables do it here
@import "variables";

// Import CoreUI styles
@import "~@coreui/coreui-pro/scss/coreui.scss";

// Some temp fixes
@import "fixes";

// If you want to add something do it here
@import "custom";
@import "../views/login/login.scss";
@import "../views/register/register.scss";

* {
	box-sizing: border-box;
}

.title-underline {
	font-weight: bold;
	font-size: 1.5rem;
	text-align: center;
	text-transform: uppercase;
}

.title-underline::after {
	content: "";
	display: block;
	height: 6px;
	width: 80px;
	margin: 8px auto;
	background-image: linear-gradient(to right, #D90712, #312882);
	border-radius: 3px;
}

button {
	background-color: #FFF;
}

.systemNotificationsIcon {
  position: absolute;
  z-index: 9999;
  color: #fff;
  font-weight: bolder;
  font-size: 1rem;
  border-radius: 50%;
  height: 32px;
  width: 32px;
  top: -10px;
  left: -10px;
  background-color: rgba(134,34,34,0.9);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.systemNotificationsValue {
  padding: 5px;
}
.systemNotificationWrapper {
  margin: 1rem;
  padding: 1rem;
  background-color: #FFF;
  border: 1px solid #aaa;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.systemNotificationPair {
  display: flex;
  margin: 0.25rem;
  justify-content: flex-start;
  align-items: center;
}
.systemNotificationsContent {
  flex: 1 1 80%;
}
.systemNotificationsFooter {
  margin-top: 1rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}
.systemNotificationsControl {
  flex: 1 1 20%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-end;
}
.msw-sn-item {
  margin: 0 2rem 0 0;
}
.msw-sn-btn {
  width: 150px;
  padding: 5px;
  border: 1px solid #aaa;
  border-radius: 5px;
  margin: 0.5rem;
}


// ---------------------------
.sn-my-appear {
  opacity: 0;
}
.sn-my-active-appear{
  opacity: 0;
}
.sn-my-done-appear{
  opacity: 0;
}
.sn-my-enter{
  opacity: 0;
  transition: opacity 300ms;
}
.sn-my-active-enter{
  opacity: 0;
}
.sn-my-done-enter{
  opacity: 0;
}
.sn-my-exit{
  opacity: 1;
  transition: opacity 300ms;
}
.sn-my-active-exit {
  opacity: 0;
}
.sn-my-done-exit{
  opacity: 0;
}



// exercises
.exe-loading {
  text-align: center;
  padding: 1rem;
}
.g-notification {
  background-color: rgba(0,0,0,0.85);
  color: #eee;
}
.g-notification-success {
  background-color: rgba(32,114,32,0.85);
  color: #eee;
}
.g-notification-danger {
  background-color: rgba(174,32,32,0.85);
  color: #eee;
}

