.DashTable {
  &-row {
    //border: 2px solid #bbb;
    margin: 0.25rem auto;
  }
  &-col {
    & > div {
      color: #000;
      font-weight: bold;
    }
    &-title {
      text-align: center;
      margin-bottom: 0;
      margin-top: 0.35em;
      font-size: 1.1rem;
      text-transform: uppercase;
    }
    &-indication-wrapper {
      margin: 0 auto;
      width: 60px;
    }
    &-indication {
      margin: 0 auto;
      width: 60px;
      background: linear-gradient(90deg, #c00, #903, #60f);
      height: 5px;
      border-radius: 3px;
    }
  }
  &-table-wrapper {
    //overflow-x: auto;
  }
  &-table {
    border-collapse: collapse;
    width: 100%;
    border-spacing: 0;
    margin: 1rem 0;
    & th, td {
      text-align: left;
      padding: 0.35rem;
    }
    & th {
      text-align: center;
    }
    & td:not(:first-child) {
      font-weight: normal;
      text-align: center;
    }
  }
}
